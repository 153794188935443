 
  .scroll {
    
    background-color: #4e98b8;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 5px;
    border: none;
    
    
  }
  
  .arrow-up {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -5px;
  }
    