 .autoComplete {
   width:  100%;
   border : 1px solid grey;
   padding-left: 5px;
   border-radius : 10px;
   box-shadow: 0 0 0 1 px rgba(114, 100, 100, 0.295) 0 2px 4px 1px rgba(0, 0, 0, .18);
   font-family: 'Kanit' , sans-serif;
   font-size: 16px;
   color: rgba(0, 0, 0, 0.73)

   
 }

 .autoComplete input{
   width: 100%;
   border : none;
   border-radius:  15px ;
   font-size: 16px;
   padding: 10px 5px;
   box-sizing: border-box;
   outline: none;
 }

 .autoComplete ul{
  list-style-type: none;
  text-align: left;
  margin : 0;
  padding: 0; 
  border-color: 1px solid grey;
}

.autoComplete li{
  cursor: pointer;
  padding: 10px 5px; 
}

.autoComplete li:hover{
  background-color: rgb(216, 215, 215)
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

li:hover{
  background:#EFF0F1  !important;
}

input.input-custom:hover {
  box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19) !important;
}

.file {
  visibility: hidden;
  position: absolute;
}
.btn-flat:hover {
  color: #4e98b8;
  background-color:white;
  border-color:#4e98b8;
}
.btn-flat{
background-color:#4e98b8;
color:white;
font-family: 'Kanit' , sans-serif;
}
.suggestions{
  position: absolute;
  z-index: 9999;
  width: 97%;
  background-color: #ffffff;
  border-style: solid;
  border-width: .75px .75px .75px .75px;
  border-color: #d2d2d2;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 5;
  box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19);
}
@media only screen and (max-width: 600px) {
  .custom{
    margin-top: 5%;
  }
  .suggestions{
    width: 91%;
  }
}