 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
 }
 
 li:hover{
   background:#EFF0F1  !important;
 }
 
 input.input-custom:hover {
   box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19) !important;
 }
 
 .file {
   visibility: hidden;
   position: absolute;
 }