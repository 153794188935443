.custom-css > ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px
    
 }
 .custom-css > ol { 
    list-style-type: decimal; 
    list-style-position: inside; 
 }
 .custom-css > ul ul, ol ul { 
    list-style-type: circle; 
    list-style-position: inside; 
    margin-left: 15px; 
 }
 .custom-css > ol ol, ul ol { 
    list-style-type: lower-latin; 
    list-style-position: inside; 
    margin-left: 15px; 
 }

 .custom-css > li {
    display: list-item;
    text-align: -webkit-match-parent;
 }

 .custom-css > li:hover {
    background-color: white !important;
 }