.btn-file {
    position: relative;
    overflow: hidden;
    margin-top: 15px;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;   
    cursor: inherit;
    display: block;
}
.btn-up:hover {
  background-color:#4e98b8;
  color:white;
}
.btn-up{
  color: #4e98b8;
  background-color:white;
  border-color:#4e98b8;
  font-family: 'Kanit' , sans-serif;
}