.App {
  text-align: center;
}

h1 , p, div ,input, span {
  font-family: 'Kanit', sans-serif;
} 

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
div.drop-down-list-menu:hover {
  background-color: #E6F1F9;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.dropdown-menu { 
    right: 0; left: auto; 
}
.dropdown-toggle::after{
    display: none;
}
.image {
   
}
.text {
    vertical-align: middle;
}
 
  .scroll {
    
    background-color: #4e98b8;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 5px;
    border: none;
    
    
  }
  
  .arrow-up {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -5px;
  }
    
.aspectratio {
    position: relative;
    height: 0;
    width: 100%;
  }
  
  .aspectratio[data-ratio="16:9"] {
    padding-top: 56.25%;
  }
  
  .aspectratio[data-ratio="4:3"] {
    padding-top: 75%;
  }
  
  .aspectratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div{
  display: block;
  }
h1{
  font-size: 40px;
  color: black;
}
p{
  padding: 10px;
  font-size: 15px;
  color: black;
}
  .homehead1{
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image:url(../../static/media/bb7.87ecfd3e.jpg)
  }

  .homehead3{
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image:url(../../static/media/bb5.800a4452.jpeg);
  }
  .box {
    background-image:url(../../static/media/bb6.2106eae5.jpg);
    width: 100%;
    height: 100%;
    padding-top: 92px;
    padding-bottom: 92px;
    background-size: cover;
}
 .focus-button  :active {
   background-color : black
 }

 .selectActive :active {
    background-color: black;
 }
 .autoComplete {
   width:  100%;
   border : 1px solid grey;
   padding-left: 5px;
   border-radius : 10px;
   box-shadow: 0 0 0 1 px rgba(114, 100, 100, 0.295) 0 2px 4px 1px rgba(0, 0, 0, .18);
   font-family: 'Kanit' , sans-serif;
   font-size: 16px;
   color: rgba(0, 0, 0, 0.73)

   
 }

 .autoComplete input{
   width: 100%;
   border : none;
   border-radius:  15px ;
   font-size: 16px;
   padding: 10px 5px;
   box-sizing: border-box;
   outline: none;
 }

 .autoComplete ul{
  list-style-type: none;
  text-align: left;
  margin : 0;
  padding: 0; 
  border-color: 1px solid grey;
}

.autoComplete li{
  cursor: pointer;
  padding: 10px 5px; 
}

.autoComplete li:hover{
  background-color: rgb(216, 215, 215)
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

li:hover{
  background:#EFF0F1  !important;
}

input.input-custom:hover {
  box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19) !important;
}

.file {
  visibility: hidden;
  position: absolute;
}
.btn-flat:hover {
  color: #4e98b8;
  background-color:white;
  border-color:#4e98b8;
}
.btn-flat{
background-color:#4e98b8;
color:white;
font-family: 'Kanit' , sans-serif;
}
.suggestions{
  position: absolute;
  z-index: 9999;
  width: 97%;
  background-color: #ffffff;
  border-style: solid;
  border-width: .75px .75px .75px .75px;
  border-color: #d2d2d2;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 5;
  box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19);
}
@media only screen and (max-width: 600px) {
  .custom{
    margin-top: 5%;
  }
  .suggestions{
    width: 91%;
  }
}
/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    background-color:#4e98b8;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font-family: "'Kanit', sans-serif",
}

.accordion__button:hover {
    background-color: #92c0d4;
}


.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    -webkit-animation: fadein 0.35s ease-in;
            animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.react-datepicker-wrapper{
    width: 100% !important
}
.react-datepicker__input-container{
    width: 100% !important
}

.input-custom{
    font-size: 16
}
.custom-css > ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px
    
 }
 .custom-css > ol { 
    list-style-type: decimal; 
    list-style-position: inside; 
 }
 .custom-css > ul ul, ol ul { 
    list-style-type: circle; 
    list-style-position: inside; 
    margin-left: 15px; 
 }
 .custom-css > ol ol, ul ol { 
    list-style-type: lower-latin; 
    list-style-position: inside; 
    margin-left: 15px; 
 }

 .custom-css > li {
    display: list-item;
    text-align: -webkit-match-parent;
 }

 .custom-css > li:hover {
    background-color: white !important;
 }
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
 }
 
 li:hover{
   background:#EFF0F1  !important;
 }
 
 input.input-custom:hover {
   box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19) !important;
 }
 
 .file {
   visibility: hidden;
   position: absolute;
 }
.btn-file {
    position: relative;
    overflow: hidden;
    margin-top: 15px;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;   
    cursor: inherit;
    display: block;
}
.btn-up:hover {
  background-color:#4e98b8;
  color:white;
}
.btn-up{
  color: #4e98b8;
  background-color:white;
  border-color:#4e98b8;
  font-family: 'Kanit' , sans-serif;
}
