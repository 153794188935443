.aspectratio {
    position: relative;
    height: 0;
    width: 100%;
  }
  
  .aspectratio[data-ratio="16:9"] {
    padding-top: 56.25%;
  }
  
  .aspectratio[data-ratio="4:3"] {
    padding-top: 75%;
  }
  
  .aspectratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div{
  display: block;
  }
h1{
  font-size: 40px;
  color: black;
}
p{
  padding: 10px;
  font-size: 15px;
  color: black;
}
  .homehead1{
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image:url(../../../src/assets/Bg/bb7.jpg)
  }

  .homehead3{
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image:url(../../../src/assets/Bg/bb5.jpeg);
  }
  .box {
    background-image:url(../../../src/assets/Bg/bb6.jpg);
    width: 100%;
    height: 100%;
    padding-top: 92px;
    padding-bottom: 92px;
    background-size: cover;
}